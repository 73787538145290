import "../../styles/importexport.scss"
import React, {useRef, useState} from "react";
import {analyticAPI} from "../../api";

export const ImportReport = ({setFile, file}) => {

  const fileInputRef = useRef(null);
  const [fileSize, setFileSize] = useState(null);
  const uploadFile = () => {
    fileInputRef.current.click();
  };

  const currentDate = () => {
    const now = new Date(); // Получаем текущую дату и время

    // Получаем часы и минуты с ведущими нулями, если нужно
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `Загружен сегодня, ${hours}:${minutes}`;
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
    const sizeInKB = (e.target.files[0].size / (1024)).toFixed(2);
    setFileSize(sizeInKB);
  }

  const downloadTemplate = () => {
    analyticAPI.get("warehouse-template/", {responseType: 'blob'}).then(
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[:T]/g, '-');

        const fileName = `Шаблон-${formattedDate}.xlsx`;
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    ).catch(err => console.log(err))
  }

  return <div className="import-report-container">
    <div className="export-template-container" onClick={downloadTemplate}>
      <img src="/images/export_template.svg" alt=""/>
      <span className="export-template-text">Шаблон для массового скачивания</span>
    </div>
    {
      !file ? (
          <div className="import-file-container" onClick={uploadFile}>
            <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{display: "none"}}/>
            <span>Перетащите шаблон для скачивания в эту область или выберите его из списка файлов нажав на кнопку добавить файл</span>
            <button className="import-file-btn">Добавить файл</button>
          </div>
        ) :
        <div className="uploaded-file-container">
          <div className="upload-container">
            <img className="excel-icon" src="/images/uploaded_excel.svg" alt=""/>
            <div className="uploaded-file-info">
              <span>{file.name}</span>
              <span className="uploaded-text">{currentDate()}, {fileSize} КБ</span>
            </div>
          </div>
          <img className={"excel-icon"} src="/images/trash_can_files.svg" onClick={() => setFile(undefined)} alt=""/>
        </div>
    }
  </div>
}