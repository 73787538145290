import React from "react";
import "../../styles/connections.css"
import {OrdersCartTab} from "./OrdersCartTab";
import {OrdersAccountsTab} from "./OrdersAccountsTab";
import {CreateStockTab} from "../CreateStockTab";
import {CheckListTab} from "../CheckListTab";

export const OrdersTabs = ({
                             setLoading,
                             platformsToFilter,
                             setPlatformsToFilter,
                             accountsToFilter,
                             setAccountsToFilter,
                             showCartTab,
                             showAccountsTab,
                             setShowCartTab,
                             setShowAccountsTab,
                             setShowCreateStockTab,
                             showCreateStockTab,
                             setShowChecklistTab,
                             showCheckListTab,
                             ordersItems,
                             positions,
                             setPositions,
                             setCheckedOrderItems,
                             handleOnCheck
                           }) => {
  return (
    <div className="control-tabs">
      {showCartTab ? <OrdersCartTab setLoading={setLoading} platforms={platformsToFilter}
                                    setPlatformsToFilter={setPlatformsToFilter}
                                    setShowCartTab={setShowCartTab} showCartTab={showCartTab}/> : <></>}
      {showAccountsTab ?
        <OrdersAccountsTab setLoading={setLoading} accountsToFilter={accountsToFilter}
                           setAccountsToFilter={setAccountsToFilter} showAccountsTab={showAccountsTab}
                           platformsToFilter={platformsToFilter}
                           setShowAccountsTab={setShowAccountsTab}/> : <></>}
      {showCreateStockTab ?
        <CreateStockTab setShowCreateStockTab={setShowCreateStockTab} showCreateStockTab={showCreateStockTab}/> : <></>}
      {
        showCheckListTab ?
          <CheckListTab setShowChecklistTab={setShowChecklistTab} setPositions={setPositions} orderItems={ordersItems}
                        positions={positions} setCheckedOrderItems={setCheckedOrderItems}
                        handleOnCheck={handleOnCheck}/> : <></>}

    </div>
  )
}
