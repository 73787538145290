import React, {useState} from "react";
import "../../styles/importexport.scss"

export const ImportExportSearch = (
  {
    searchQuery,
    setSearchQuery
  }
) =>{
  const [isSearchVisible, setIsSearchVisible] = useState(true);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }


  return (
    <>
      <div className="btn btn-negative search-input-container">
        <img src="/images/search.svg" width="24px" height="24px" alt=""
        />
        {isSearchVisible &&
          <input
            type="text"
            className="import-export-search-input"
            placeholder="Поиск по наименованию и артикулу"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        }
      </div>
    </>
  )
}