import React, {useEffect, useState} from "react";
import "../../styles/importexport.scss";
import Checkbox from "react-custom-checkbox";
import Loader from "../Loader";
import {analyticAPI} from "../../api";
import {NotificationManager} from "react-notifications";

export const ImportExportTable = ({
                                    loading,
                                    products,
                                    handleOnCheck,
                                    positions,
                                    setOrderBy,
                                    setCheckedOrderItems,
                                    setPositions,
                                  }) => {
  const [itemsOpenStates, setItemsOpenStates] = useState({});
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
  const [currentOrdering, setCurrentOrdering] = useState(null);
  const [isEditableRaws, setIsEditableRaws] = useState([])
  const [editData, setEditData] = useState(new Map());
  const [currentProductRests, setCurrentProductRests] = useState(new Map());


  const updateProduct = (product, isSetPart) => {
    if (!isEditableRaws.includes(product.id)) {
      console.log("not need to update")
      return
    }

    const url = product.products === undefined || isSetPart ? "update-product/" : "update-set/"

    analyticAPI.patch(url, {
        account_id: product.account_id,
        sku: product.sku,
        vendor: product.vendor,
        barcodes: product.barcodes,

        price: editData[`${product.id}_${isSetPart ? "setpart_" : ""}saleprice_mp`],
        min_price: editData[`${product.id}_${isSetPart ? "setpart_" : ""}minprice`],
        discounted_price: editData[`${product.id}_${isSetPart ? "setpart_" : ""}discounted_saleprice_mp`],

        new_stock: editData[`${product.id}_${isSetPart ? "setpart_" : ""}rest`],
        old_stock: currentProductRests[`${product.id}_${isSetPart ? "setpart_" : ""}rest`],
      }
    ).then(
      () => {
        NotificationManager.info("Данные по товару обновлены!")
        updateCurrentProductRests();
      }
    )
      .catch(err => {
        console.log(err)
        NotificationManager.error(err.response.data.error)
      })
  }

  const updateCurrentProductRests = () => {
    const tempProductRestMap = {}
    for (const product of products) {
      if (product.products !== undefined) {
        for (const setPart of product.products) {
          tempProductRestMap[`${setPart.product.id}_setpart_rest`] = editData[`${product.id}_setpart_rest`]
        }
      }
      tempProductRestMap[`${product.id}_rest`] = editData[`${product.id}_rest`]
    }
    setCurrentProductRests(tempProductRestMap)
  }


  useEffect(() => {
    const tempMap = {}
    const tempProductRestMap = {}
    for (const product of products) {
      if (product.products !== undefined) {
        for (const setPart of product.products) {
          tempMap[`${setPart.product.id}_setpart_minprice`] = setPart.product.connected_product?.price
          tempMap[`${setPart.product.id}_setpart_discounted_saleprice_mp`] = setPart.discounted_sale_price
          tempMap[`${setPart.product.id}_setpart_saleprice_mp`] = setPart.sale_price
          tempMap[`${setPart.product.id}_setpart_rest`] = setPart.rest_quantity !== null ? setPart.rest_quantity : 0
          tempProductRestMap[`${setPart.product.id}_setpart_rest`] = setPart.rest_quantity !== null ? setPart.rest_quantity : 0
        }
      }
      tempMap[`${product.id}_minprice`] = product.connected_product !== undefined ? product.connected_product?.price : product.price
      tempMap[`${product.id}_discounted_saleprice_mp`] = product.discounted_sale_price
      tempMap[`${product.id}_saleprice_mp`] = product.sale_price
      tempMap[`${product.id}_rest`] = product.rest_quantity !== undefined ? product.rest_quantity : 0
      tempProductRestMap[`${product.id}_rest`] = product.rest_quantity !== undefined ? product.rest_quantity : 0
    }

    setEditData(tempMap)
    setCurrentProductRests(tempProductRestMap)
  }, [products])

  const handleChange = (event) => {
    const {name, value} = event.target;
    setEditData((prevInputs) => ({
      ...prevInputs, [name]: value
    }));
  };


  const onEdit = (productId) => {
    if (isEditableRaws.includes(productId)) {
      setIsEditableRaws(isEditableRaws.filter(raw => raw !== productId))
    } else {
      setIsEditableRaws([...isEditableRaws, productId])
    }
  }

  const handleMasterCheckboxChange = () => {
    if (!masterCheckboxChecked) {
      setCheckedOrderItems([...products]);
      setPositions(products.map((product) => product.id));
    } else {
      setCheckedOrderItems([]);
      setPositions([]);
    }

    setMasterCheckboxChecked(!masterCheckboxChecked);
  };

  const sortTable = (sorting) => {
    let cur_ordering = sorting;
    if (currentOrdering === cur_ordering) {
      cur_ordering = "-" + cur_ordering;
    }
    setCurrentOrdering(cur_ordering);
    setOrderBy(`${cur_ordering},name`);
  };

  const openCloseSet = (itemId) => {
    setItemsOpenStates((prevStates) => {
      return {...prevStates, [itemId]: !prevStates[itemId]};
    });
  };

  return loading ? (<Loader/>) : (<table className="importexport-table ">
    <thead>
    <tr className="table-header">
      <th className="checkbox-col">
        <div className="checkbox-col-container">
          <Checkbox
            className="custom-checkbox"
            checked={masterCheckboxChecked}
            onChange={handleMasterCheckboxChange}
            icon={<img
              src="/images/checbox.svg"
              style={{width: "28px"}}
              alt=""
            />}
          />
          <span className="counter">{positions.length}</span>
        </div>
      </th>
      <th>
        <div className="th-content">
          <p>Наименование товара на маркетплейсе</p>
        </div>
      </th>
      <th>
        <div className="th-content" style={{minWidth: "100px"}}>
          <p>Тип товара</p>
        </div>
      </th>
      <th>
        <div className="th-content" style={{minWidth: "110px"}}>
          <p>Цена РРЦ</p>
        </div>
      </th>
      <th>
        <div className="th-content">Цена продажи <br/> (до скидки)</div>
      </th>
      <th>
        <div className="th-content">Цена продажи <br/> (после скидки)</div>
      </th>
      <th>
        <div className="th-content">
          <p>Маркетплейс</p>
          <div style={{cursor: "pointer"}} onClick={() => sortTable("account__platform__name")}>
            <img
              id="doc"
              className=""
              width="16px"
              height="16px"
              src="/images/filter_sign.svg"
              alt=""
            />
          </div>
        </div>
      </th>
      <th>
        <div className="th-content">
          <p>Магазин</p>
          <div style={{cursor: "pointer"}} onClick={() => sortTable("account__name")}>
            <img
              id="doc"
              className=""
              width="16px"
              height="16px"
              src="/images/filter_sign.svg"
              alt=""
            />
          </div>
        </div>
      </th>
      <th>
        <div className="th-content">
          <p>Бренд</p>
        </div>
      </th>
      <th>
        <div className="th-content">
          <p>Остатки</p>
        </div>
      </th>
      <th>
      </th>
    </tr>
    </thead>
    <tbody>
    {products.map((product) => (<>
      <tr
        key={product.id}
        id={product.id}
      >
        <td className="checkbox-col">
          <div className="table-header-item-container checkbox-col-container">
            <Checkbox
              key={product.id}
              checked={positions.includes(product.id)}
              onChange={() => handleOnCheck(product)}
              className="custom-checkbox"
              icon={<img
                src="/images/checbox.svg"
                style={{width: "28px"}}
                alt=""
              />}
            />
          </div>
        </td>
        <td onClick={() => openCloseSet(product.id)}>
          <div className={"td-container"}>
            {product.products !== undefined ? (<img
              alt=""
              className={itemsOpenStates[product.id] ? "n-deg-rotated" : ""}
              src="/images/shevron_right.svg"
            ></img>) : ("")}
            <p>
              {product.name} <br/> {product.barcodes[0]}
            </p>
          </div>
        </td>
        <td>
          <p>{product.products !== undefined ? "Комплект" : "Товар"}</p>
        </td>
        <td>
          {isEditableRaws.includes(product.id) ?
            <div className="import-input-container">
              <input
                key={`${product.id}_minprice`}
                name={`${product.id}_minprice`}
                type="text"
                className="edit-fields-input"
                value={editData[`${product.id}_minprice`]}
                onChange={handleChange}
              />
              <span className="import-span-container">₽</span>
            </div>
            : <p>{editData[`${product.id}_minprice`] !== null ? editData[`${product.id}_minprice`] : 0} ₽</p>}
        </td>
        <td>
          {isEditableRaws.includes(product.id) ? (<div className="import-input-container">
              <input
                key={`${product.id}_saleprice_mp`}
                name={`${product.id}_saleprice_mp`}
                type="text"
                className="edit-fields-input"
                value={editData[`${product.id}_saleprice_mp`]}
                onChange={handleChange}
              />
              <span className="import-span-container">₽</span>
            </div>) :
            <p> {editData[`${product.id}_saleprice_mp`] !== null ? editData[`${product.id}_saleprice_mp`] : 0} ₽</p>}
        </td>
        <td>
          {isEditableRaws.includes(product.id) ? (<div className="import-input-container">
              <input
                key={`${product.id}_discounted_saleprice_mp`}
                name={`${product.id}_discounted_saleprice_mp`}
                type="text"
                className="edit-fields-input"
                value={editData[`${product.id}_discounted_saleprice_mp`]}
                onChange={handleChange}
              />
              <span className="import-span-container">₽</span>
            </div>) :
            <p> {editData[`${product.id}_discounted_saleprice_mp`] !== null ? editData[`${product.id}_discounted_saleprice_mp`] : 0} ₽</p>}
        </td>
        <td>
          <p>{product.marketplace}</p>
        </td>
        <td>
          <p>{product.account_name}</p>
        </td>
        <td>
          <p>{product.brand !== null ? product.brand : "-"}</p>
        </td>
        <td>{isEditableRaws.includes(product.id) ?
          <div className="import-input-container">
            <input
              key={`${product.id}_rest`}
              name={`${product.id}_rest`}
              type="text"
              className="edit-fields-input import-font"
              value={editData[`${product.id}_rest`] || 0}
              onChange={handleChange}
            />
            <span className="import-span-container">шт</span>
          </div>
          : <p> {editData[`${product.id}_rest`] !== null ? editData[`${product.id}_rest`] + " шт" : 0 + " шт"} </p>}
        </td>
        <td>
          <img className="edit-field-image"
               src={isEditableRaws.includes(product.id) ? "/images/save_field.svg" : "/images/edit_field.svg"}
               alt="" onClick={() => {
            onEdit(product.id);
            updateProduct(product, false);
          }}/>
        </td>
      </tr>
      {product.products === undefined ? ("") : (<>
        {product.products.map((setPart) => (<tr
          key={product.id + "_" + setPart.id}
          className={itemsOpenStates[product.id] ? "" : "no-display"}
        >
          <td className="checkbox-col">
            <div className="table-header-item-container"></div>
          </td>
          <td>
            <p className={"set-order-number"} style={{display: "flex"}}>
              {setPart.product.name}
            </p>
          </td>
          <td>
            <p>Товар</p>
          </td>
          <td>
          </td>
          <td>
            {isEditableRaws.includes(setPart.product.id) ?
              <div className="import-input-container">
                <input
                  key={`${setPart.product.id}_setpart_saleprice_mp`}
                  name={`${setPart.product.id}_setpart_saleprice_mp`}
                  type="text"
                  className="edit-fields-input"
                  value={editData[`${setPart.product.id}_setpart_saleprice_mp`]}
                  onChange={handleChange}
                />
                <span className="import-span-container">₽</span>
              </div>
              :
              <p>{editData[`${setPart.product.id}_setpart_saleprice_mp`] !== null ? editData[`${setPart.product.id}_setpart_saleprice_mp`] : 0} ₽</p>}
          </td>
          <td>
            {isEditableRaws.includes(setPart.product.id) ?
              <div className="import-input-container">
                <input
                  key={`${setPart.product.id}_setpart_discounted_saleprice_mp`}
                  name={`${setPart.product.id}_setpart_discounted_saleprice_mp`}
                  type="text"
                  className="edit-fields-input"
                  value={editData[`${setPart.product.id}_setpart_discounted_saleprice_mp`]}
                  onChange={handleChange}
                />
                <span className="import-span-container">₽</span>
              </div>
              :
              <p>{editData[`${setPart.product.id}_setpart_discounted_saleprice_mp`] !== null ? editData[`${setPart.product.id}_setpart_discounted_saleprice_mp`] : 0} ₽</p>}
          </td>
          <td>
            <p>{product.marketplace}</p>
          </td>
          <td>
            <p>{product.account_name}</p>
          </td>
          <td>
            <p>{setPart.product.brand !== undefined ? setPart.product.brand : "-"}</p>
          </td>
          <td>
            {isEditableRaws.includes(setPart.product.id) ?
              <div className="import-input-container">
                <input
                  key={`${setPart.product.id}_setpart_rest`}
                  name={`${setPart.product.id}_setpart_rest`}
                  type="text"
                  className="edit-fields-input import-font"
                  value={editData[`${setPart.product.id}_setpart_rest`] || 0}
                  onChange={handleChange}
                />
                <span className="import-span-container">шт</span>
              </div>
              :
              <p> {editData[`${setPart.product.id}_setpart_rest`] !== null ? editData[`${setPart.product.id}_setpart_rest`] + " шт" : 0 + " шт"} </p>}
          </td>
          <td>
            <img src={isEditableRaws.includes(setPart.product.id) ? "/images/save_field.svg" : "/images/edit_field.svg"}
                 className="edit-field-image" alt="" onClick={() => {
              onEdit(setPart.product.id);
              updateProduct(setPart.product, true);
            }}/>
          </td>
        </tr>))}
      </>)}
    </>))}
    </tbody>
  </table>)
};
