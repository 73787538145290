import React, {useEffect, useState} from "react";
import Select from "react-select";
import Checkbox from "react-custom-checkbox";
import "../styles/connections.css"
import {analyticAPI} from "../api";


export const CheckListTab = ({
                               setShowChecklistTab,
                               orderItems,
                               handleOnCheck,
                               positions,
                             }) => {


  const [checkListOrderItems, setCheckListOrderItems] = useState(orderItems)
  const [brandChoices, setBrandChoices] = useState([])
  const [marketPlaceChoices, setMarketPlaceChoices] = useState([])
  const [selectedBrand, setSelectedBrand] = useState("")
  const [selectedMarketPlace, setSelectedMarketplace] = useState("")

  useEffect(() => {
    let filtered = orderItems
    if (selectedBrand !== "") {
      filtered = orderItems.filter(orderItem => orderItem.product.brand === selectedBrand.value)
    }
    if (selectedMarketPlace !== "") {
      filtered = filtered.filter(orderItem => orderItem.order.platform_name === selectedMarketPlace.label)
    }
    setCheckListOrderItems(filtered)
  }, [selectedBrand, selectedMarketPlace])

  useEffect(()=>{
    setCheckListOrderItems(orderItems)
  }, [orderItems])

  useEffect(() => {
    analyticAPI.get(`get-order-items-choices/?field=product__brand&ids=${orderItems.map(orderItem => orderItem.id)}`).then(
      res => setBrandChoices(res.data.choices.map(brand => ({value: brand, label: brand})))
    ).catch(err => console.log(err))

    analyticAPI.get(`marketplace-types/?with_moy_sklad=1`).then(
      response => {
        setMarketPlaceChoices(response.data.map((marketplace_type, idx) => ({value: idx, label: marketplace_type})))
      }
    )
  }, [orderItems])

  const exportExcel = () => {
    analyticAPI.post(`order-items/export/`, {
      order_items: orderItems.map(orderItem => ({
        id: orderItem.id,
        product_name: orderItem.product.name,
        express_rests_quantity: orderItem.express_rests_quantity,
        south_rests_quantity: orderItem.south_rests_quantity,
        total_rests_quantity: orderItem.total_rests_quantity,
      }))
    }, {
      responseType: 'blob'
    }).then(
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[:T]/g, '-');

        const fileName = `Отчет-${formattedDate}.xlsx`;
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  }

  return (<div className="shops assembly-list-orders-container check-list-orders-container">
    <div className="shops-content">
      <div className="shops-header">
        <p className="shops-header-text">Проверка остатков</p>
        <img className="shops-header-cross" src="/images/cross.svg" alt="" onClick={() => setShowChecklistTab(false)}
        />
      </div>
      <div className="shops-filters">
        <Select
          defaultValue={undefined}
          onChange={(e) => {
            setSelectedBrand(e)
          }}
          options={brandChoices}
          // isDisabled={true}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Бренд"
        />
        <Select
          defaultValue={undefined}
          onChange={(e) => {
            setSelectedMarketplace(e)
          }}
          options={marketPlaceChoices}
          // isDisabled={true}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Маркетплейсы"
        />
        <button className="btn btn-negative no-background search-input-container quantity-sort-button">
          <p>Шт</p><img alt=""
                        className="short-time-input-image"
                        src="/images/up_arrow.svg" width="16px" height="16px"/>
        </button>
      </div>
    </div>
    <div className="assembly-table-container">
      <table className="orders-table assembly-table">
        {checkListOrderItems.length ? (
          <thead>
          <tr className="checkbox-col">
            <th>Наименование</th>
            <th>Экспресс</th>
            <th>Юг</th>
            <th>Всего</th>
          </tr>
          </thead>
        ) : <></>
        }

        <tbody>
        {checkListOrderItems.length ? checkListOrderItems.map((orderItem) => (<tr className="table-header">
          <td>
            <div className="table-header-item-container">
              <Checkbox
                checked={positions.includes(orderItem.id)}
                onChange={() => handleOnCheck(orderItem)}
                className="custom-checkbox"
                icon={<img src="/images/checbox.svg" style={{width: "28px"}} alt=""/>}/>
            </div>
          </td>
          <td>
            <p>{orderItem.product.name}</p>
          </td>
          <td>
            <p>{orderItem.south_rests_quantity}</p>
          </td>
          <td>
            <p>{orderItem.order.express__rests_quantity}</p>
          </td>
          <td>
            <p>{orderItem.total_rests_quantity}</p>
          </td>
        </tr>)) : <div className="empty-order-items">
          <img className="empty-order-image" src="/images/orderItems.svg" alt=""></img>
          <p className="empty-order-header">У вас не выбраны товары</p>
          <p className="empty-order-text">Чтобы добавить товары в проверку остатков выберите товар через нажатие на
            галочку.</p>
        </div>}
        </tbody>
      </table>
    </div>
    {orderItems.length ? (<div className="order-items-action-panel">
      <div className={"order-items-action-panel-item"}>
        <img src="/images/orderItemsExport.svg" onClick={exportExcel} alt="" style={{cursor: "pointer"}}/>
      </div>
      <div className={"order-items-action-panel-item"}>
        <button className="btn btn-negative no-background search-input-container">Отменить
        </button>
        <button className="btn btn-primary">Сохранить</button>
      </div>
    </div>) : <></>}
  </div>)
}