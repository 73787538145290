import Checkbox from "react-custom-checkbox";
import React from "react";


export const ExportReport = ({checkedFieldsToExport, setCheckedFieldsToExport, fieldsToExport}) => {

  const handleOnCheck = (value) => {
    if (checkedFieldsToExport.includes(value)) {
      setCheckedFieldsToExport(checkedFieldsToExport.filter(field => field !== value))
    } else {
      setCheckedFieldsToExport([...checkedFieldsToExport, value])
    }
  }

  return (
    <div className="export-settings-container">
      {fieldsToExport.map((field) =>
        <div className="export-checkbox-container">
          <Checkbox
            id={fieldsToExport}
            checked={true}
            onChange={() => handleOnCheck(field)}
            className="custom-checkbox"
            icon={<img src="/images/checbox.svg" style={{width: "28px"}} alt=""/>}/>
          {field}
        </div>)}
    </div>)
}