import "../../styles/connections.css"

export const OrdersHeader = ({
                               ordersType,
                               counts,
                               setOrdersType,
                               setLinked,
                               setLink
                             }) => {

  const filterOrders = (orderType, ) => {
    if (orderType !== "") {
      setLinked(true)
    } else {
      setLinked(false)
    }
    setOrdersType(orderType)
    setLink(orderType)
  }

  return (
    <div className="filters-container">
      <div className="filters">
        <div className={ordersType === "new" ? "filter-label" : ""} onClick={() => filterOrders("new")}>
          <p className={ordersType === "new" ? "selected-filter" : "filter"}>Новые заказы <span className="text-red">{counts["new"]}</span></p>
        </div>
        <div className={ordersType === "urgent" ? "filter-label" : ""} onClick={() => filterOrders("urgent")}>
          <p className={ordersType === "urgent" ? "selected-filter" : "filter"}>Срочные <span className="text-red">{counts["urgent"]}</span></p>
        </div>
        <div className={ordersType === "not_shipped" ? "filter-label" : ""} onClick={() => filterOrders("not_shipped")}>
          <p className={ordersType === "not_shipped" ? "selected-filter" : "filter"}>Не отправленные <span className="text-red">{counts["not_shipped"]}</span></p>
        </div>
        <div className={ordersType === "" ? "filter-label" : ""} onClick={() => filterOrders("")}>
          <p className={ordersType === "" ? "selected-filter" : "filter"}>Все <span className="text-red">{counts["all"]}</span></p>
        </div>
      </div>
    </div>
  )

}
