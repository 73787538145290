import React, { useEffect, useState } from "react";
import "../../styles/connections.css";
import Checkbox from "react-custom-checkbox";
import Loader from "../Loader";

export const OrdersTable = ({
  loading,
  ordersItems,
  handleOnCheck,
  positions,
  setAssemblyListId,
  showAssemblyList,
  setShowAssemblyList,
  customTableClassname,
  setOrderBy,
  setCheckedOrderItems,
  setPositions,
}) => {
  const [itemsOpenStates, setItemsOpenStates] = useState({});
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
  const [currentOrdering, setCurrentOrdering] = useState(null);

  const handleOpenAssemblyList = (listId) => {
    setAssemblyListId(listId);
    setShowAssemblyList(!showAssemblyList);
  };

  const base64FileHeaderMapper = (fileBase64) => {
    let fileHeader = new Map();

    //get the first 3 char of base64
    fileHeader.set("/9j", "jpg");
    fileHeader.set("iVB", "png");
    fileHeader.set("Qk0", "bmp");
    fileHeader.set("SUk", "tiff");
    fileHeader.set("JVB", "pdf");
    fileHeader.set("UEs", "ofd");
    fileHeader.set("PD9", "svg");

    let res = "";

    fileHeader.forEach((v, k) => {
      if (k === fileBase64.substr(0, 3)) {
        res = v;
      }
    });

    //if file is not supported
    if (res === "") {
      res = "pdf";
    }

    //return map value
    return res;
  };

  let sortTable = (sorting) => {
    let cur_ordering = sorting;
    if (currentOrdering === cur_ordering) {
      cur_ordering = "-" + cur_ordering;
    }
    setCurrentOrdering(cur_ordering);
    setOrderBy(`${cur_ordering},order__number`);
  };

  let openCloseSet = (itemId) => {
    setItemsOpenStates((prevStates) => {
      return { ...prevStates, [itemId]: !prevStates[itemId] };
    });
  };

  const convertDate = (date) => {
    let numbers = date.split("-");
    return `${numbers[2]}.${numbers[1]}.${numbers[0]}`;
  };

  useEffect(() => {
    let items = {};
    for (let i = 0; i < ordersItems.length; i++) {
      items[ordersItems[i].id] = false;
    }
    setItemsOpenStates(items);
  }, [ordersItems]);

  const handleMasterCheckboxChange = () => {
    if (!masterCheckboxChecked) {
      setCheckedOrderItems([...ordersItems]);
      setPositions(ordersItems.map((orderItem) => orderItem.id));
    } else {
      setCheckedOrderItems([]);
      setPositions([]);
    }

    setMasterCheckboxChecked(!masterCheckboxChecked);
  };

  return loading ? (
    <Loader />
  ) : (
    <table
      className={
        "orders-table " + (customTableClassname ? customTableClassname : "")
      }
    >
      <thead>
        <tr className="table-header">
          {!customTableClassname && (
            <th className="checkbox-col">
              <div className="checkbox-col-container">
                <Checkbox
                  className="custom-checkbox"
                  checked={masterCheckboxChecked}
                  onChange={handleMasterCheckboxChange}
                  icon={
                    <img
                      src="/images/checbox.svg"
                      style={{ width: "28px" }}
                      alt=""
                    />
                  }
                />
                <span className="counter">{positions.length}</span>
              </div>
            </th>
          )}
          <th>
            <div className="th-content">
              <p>№ заказа</p>
            </div>
          </th>
          <th>
            <div className="th-content">
              <p>Маркетплейс</p>
            </div>
          </th>
          <th>
            <div className="th-content">
              <p>Магазин</p>
            </div>
          </th>
          <th>
            <div className="th-content">Тип Доставки</div>
          </th>
          <th>
            <div className="th-content">
              <p>Наименование</p>
            </div>
          </th>
          <th>
            <div className="th-content">
              <p>Бренд</p>
              <div onClick={() => sortTable("product__brand")}>
                <img
                  id="doc"
                  className=""
                  width="16px"
                  height="16px"
                  src="/images/filter_sign.svg"
                  alt=""
                />
              </div>
            </div>
          </th>
          {!customTableClassname && (
            <>
              <th>
                <div className="th-content">
                  <p>Количество штук</p>
                </div>
              </th>
              <th>
                <div className="th-content">
                  <p>Баркод</p>
                </div>
              </th>
              <th>
                <div className="th-content">
                  <p>Дата и время поступления</p>
                  <div onClick={() => sortTable("order__created_dt")}>
                    <img
                      id="doc"
                      className=""
                      width="16px"
                      height="16px"
                      src="/images/filter_sign.svg"
                      alt=""
                    />
                  </div>
                </div>
              </th>
            </>
          )}
          <th>
            <div className="th-content">
              <p> Дата и время отгрузки</p>
              <div onClick={() => sortTable("order__shipped_dt")}>
                <img
                  id="doc"
                  className=""
                  width="16px"
                  height="16px"
                  src="/images/filter_sign.svg"
                  alt=""
                />
              </div>
            </div>
          </th>
          {!customTableClassname && (
            <>
              <th>
                <div className="th-content">
                  <p>Статус</p>
                  <div onClick={() => sortTable("order__status__status_code")}>
                    <img
                      id="doc"
                      className=""
                      width="16px"
                      height="16px"
                      src="/images/filter_sign.svg"
                      alt=""
                    />
                  </div>
                </div>
              </th>
            </>
          )}
          <th>
            <div className="th-content">
              <p>Лист сборки</p>
            </div>
          </th>
          <th>
            <div className="th-content">
              <p>Этикетки</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {ordersItems.map((orderItem) => (
          <>
            <tr
              key={orderItem.id}
              id={orderItem.id}
              className={
                orderItem.order.delivery_type === 3
                  ? "urgent"
                  : orderItem.product.is_kgt === false
                    ? orderItem.product.set != null
                      ? "is_set"
                      : ""
                    : "kgt"
              }
            >
              {customTableClassname ? (
                ""
              ) : (
                <td className="checkbox-col">
                  <div className="table-header-item-container checkbox-col-container">
                    <Checkbox
                      // disabled={!!orderItem.assembly_list && !showAssemblyList}
                      checked={positions.includes(orderItem.id)}
                      onChange={() => handleOnCheck(orderItem)}
                      className="custom-checkbox"
                      icon={
                        <img
                          src="/images/checbox.svg"
                          style={{ width: "28px" }}
                          alt=""
                        />
                      }
                    />
                  </div>
                </td>
              )}
              <td onClick={() => openCloseSet(orderItem.id)}>
                <div className={"td-container"}>
                  {customTableClassname ? (
                    ""
                  ) : orderItem.product.set != null ? (
                    <img
                      alt=""
                      className={
                        itemsOpenStates[orderItem.id] ? "n-deg-rotated" : ""
                      }
                      src="/images/shevron_right.svg"
                    ></img>
                  ) : (
                    ""
                  )}
                  <p>
                    {orderItem.order.platform_name !== "Wildberries"
                      ? orderItem.order.number
                      : orderItem.external_id}
                  </p>
                </div>
              </td>
              <td>
                <p>{orderItem.order.platform_name}</p>
              </td>
              <td>
                <p>{orderItem.market_name}</p>
              </td>
              <td>
                <p>{orderItem.order.delivery_type}</p>
              </td>
              <td>
                <p>{orderItem.product.name}</p>
              </td>
              <td>
                <p>{orderItem.product.brand ? orderItem.product.brand : "-"}</p>
              </td>
              {customTableClassname ? (
                ""
              ) : (
                <td>
                  <p>{orderItem.quantity ? orderItem.quantity : "0"} шт</p>
                </td>
              )}
              {customTableClassname ? (
                ""
              ) : (
                <td>
                  <p>{orderItem.product.barcodes[0]}</p>
                </td>
              )}
              {customTableClassname ? (
                ""
              ) : (
                <td>
                  <p>{convertDate(orderItem.order.created_dt)}</p>
                </td>
              )}
              <td>
                <p>
                  {orderItem.order.shipped_dt
                    ? convertDate(orderItem.order.shipped_dt)
                    : "-"}
                </p>
              </td>
              {customTableClassname ? (
                ""
              ) : (
                <td>
                  <div className="status-container">
                    <div
                      className="status-circle"
                      style={{ background: orderItem.order.status_color }}
                    ></div>
                    <p>{orderItem.order.status_name}</p>
                  </div>
                </td>
              )}
              <td>
                {orderItem.assembly_list ? (
                  <div className="assembly-list-cell-container">
                    <p>Лист сборки № {orderItem.assembly_list}</p>
                    <img
                      src="/images/assemblylist.svg"
                      alt=""
                      onClick={() =>
                        handleOpenAssemblyList(orderItem.assembly_list)
                      }
                    />
                  </div>
                ) : (
                  <div className="assembly-list-cell-container">
                    <p>-</p>
                  </div>
                )}
              </td>
              <td>
                <div className="label-img-cell-container">
                  {orderItem.sticker ? (
                    <a
                      href={
                        "data:application/" +
                        base64FileHeaderMapper(orderItem.sticker) +
                        ";base64," +
                        orderItem.sticker
                      }
                      download={
                        "sticker." + base64FileHeaderMapper(orderItem.sticker)
                      }
                    >
                      Этикетка
                    </a>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </td>
            </tr>
            {customTableClassname || orderItem.product.set == null ? (
              ""
            ) : (
              <>
                {orderItem.product.set.products.map((setPart) => (
                  <tr
                    key={orderItem.id + "_" + setPart.id}
                    className={
                      (setPart.product.is_kgt ? "kgt" : "is_set") +
                      " " +
                      (itemsOpenStates[orderItem.id] ? "" : "no-display")
                    }
                  >
                    {customTableClassname ? (
                      ""
                    ) : (
                      <td className="checkbox-col">
                        <div className="table-header-item-container"></div>
                      </td>
                    )}
                    <td>
                      <p className={"set-order-number"}>
                        {orderItem.order.platform_name !== "Wildberries"
                          ? orderItem.order.number
                          : orderItem.external_id}
                      </p>
                    </td>
                    <td>
                      <p>{orderItem.order.platform_name}</p>
                    </td>
                    <td>
                      <p>{setPart.product.name}</p>
                    </td>
                    <td>
                      <p>
                        {setPart.product.brand ? setPart.product.brand : "-"}
                      </p>
                    </td>
                    {customTableClassname ? (
                      ""
                    ) : (
                      <td>
                        <p>{setPart.quantity ? setPart.quantity : "0"} шт</p>
                      </td>
                    )}
                    {customTableClassname ? (
                      ""
                    ) : (
                      <td>
                        <p>{setPart.product.barcodes[0]}</p>
                      </td>
                    )}
                    {customTableClassname ? (
                      ""
                    ) : (
                      <td>
                        <p>{orderItem.order.created_dt}</p>
                      </td>
                    )}
                    <td>
                      <p>
                        {orderItem.order.shipped_dt
                          ? orderItem.order.shipped_dt
                          : "-"}
                      </p>
                    </td>
                    {customTableClassname ? (
                      ""
                    ) : (
                      <td>
                        <div className="status-container">
                          <div
                            className="status-circle"
                            style={{ background: orderItem.order.status_color }}
                          ></div>
                          <p>{orderItem.order.status_name}</p>
                        </div>
                      </td>
                    )}
                    <td>
                      {orderItem.assembly_list ? (
                        <div className="assembly-list-cell-container">
                          <p>Лист сборки № {orderItem.assembly_list}</p>
                          <img
                            src="/images/assemblylist.svg"
                            alt=""
                            onClick={() =>
                              handleOpenAssemblyList(orderItem.assembly_list)
                            }
                          />
                        </div>
                      ) : (
                        <div className="assembly-list-cell-container">
                          <p>-</p>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="label-img-cell-container">
                        {orderItem.sticker ? (
                          <a
                            href={
                              "data:application/" +
                              base64FileHeaderMapper(orderItem.sticker) +
                              ";base64," +
                              orderItem.sticker
                            }
                            download={
                              "sticker." +
                              base64FileHeaderMapper(orderItem.sticker)
                            }
                          >
                            Этикетка
                          </a>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </>
        ))}
      </tbody>
    </table>
  );
};
