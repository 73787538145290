import React, {useEffect, useState} from "react";
import Select from "react-select";
import {analyticAPI} from "../api";

export const CreateStockTab = ({setShowCreateStockTab, showCreateStockTab}) => {
  const [stocks, setStocks] = useState([])
  const [mainStock, setMainStock] = useState(undefined)
  const [additionalStock, setAdditionalStock] = useState(undefined)
  const [selectedMainStock, setSelectedMainStock] = useState(undefined)
  const [selectedAdditionalStock, setSelectedAdditionalStock] = useState(undefined)

  useEffect(() => {
    analyticAPI.get("stocks/?is_additional=1").then(
      res => {
        if (res.data.results.length !== 0) {
          setAdditionalStock({value: res.data.results[0].id, label: res.data.results[0].name})
        }
      }
    )
    analyticAPI.get("stocks/?is_main=1").then(
      res => {
        if (res.data.results.length !== 0) {
          setMainStock({value: res.data.results[0].id, label: res.data.results[0].name})
        }
      }
    )
  }, [])

  const updateStocks = () => {
    if (mainStock !== undefined && selectedMainStock !== undefined) {
      analyticAPI.patch(`stocks/${mainStock.value}/`, {is_main: false}).catch(err => console.log(err))
    }
    if (selectedMainStock !== undefined) {
      analyticAPI.patch(`stocks/${selectedMainStock.value}/`, {is_main: true}).then(
        res => setMainStock(selectedMainStock)
      ).catch(err => console.log(err))
    }

    if (additionalStock !== undefined && selectedAdditionalStock !== undefined) {
      analyticAPI.patch(`stocks/${additionalStock.value}/`, {is_additional: false}).catch(err => console.log(err))
    }

    if (selectedAdditionalStock !== undefined) {
      analyticAPI.patch(`stocks/${selectedAdditionalStock.value}/`, {is_additional: true}).then(
        res => setAdditionalStock(selectedAdditionalStock)
      ).catch(err => console.log(err))
    }

  }

  useEffect(() => {
    analyticAPI.get("stocks/").then(
      res => setStocks(res.data.results)
    ).catch(err => console.log(err))
  }, [])

  return (
    <div className="shops">
      <div className="shops-content warehouse-shops-content">
        <div className="shops-header">
          <p className="shops-header-text">Склад отгрузки</p>
          <img className="shops-header-cross" src="/images/cross.svg" alt=""
               onClick={() => setShowCreateStockTab(!showCreateStockTab)}/>
        </div>
        <div className="stocks-create">
          <div className="creation-field">
            <p className="label-text">Основной склад</p>
            <Select
              value={selectedMainStock ? selectedMainStock : mainStock}
              onChange={(e) => {
                setSelectedMainStock(e)
              }}
              options={stocks.map(stock => ({value: stock.id, label: stock.name}))}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Выбор склада"
            />
          </div>
          <div className="creation-field">
            <p className="label-text">Дополнительный склад</p>
            <Select
              value={selectedAdditionalStock ? selectedAdditionalStock : additionalStock}
              onChange={(e) => {
                setSelectedAdditionalStock(e)
              }}
              options={stocks.map(stock => ({value: stock.id, label: stock.name}))}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Выбор склада"
            />
          </div>
        </div>
        <div className="stocks-create-action-panel">
          <div className="stocks-create-action-panel">
            <button className="btn btn-negative no-background search-input-container"
                    onClick={() => setShowCreateStockTab(!showCreateStockTab)}>Отменить
            </button>
            <button className="btn btn-primary" onClick={updateStocks}>Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  )
}