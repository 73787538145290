import React, {useEffect, useState} from "react";
import "../styles/history.scss"
import {useAuth} from "../components/AuthProvider";
import Select from "react-select";
import {OrdersTable} from "../components/orders/OrdersTable";
import {analyticAPI} from "../api";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell, Legend, Bar, Rectangle, BarChart
} from 'recharts';
import {AssemblyReadOnlyList} from "../components/AssemblyReadOnlyList";
import {ShipmentTimeSelector} from "../components/ShipmentTimeSelector";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

export const HistoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [ordersItems, setOrdersItems] = useState([]);
  const [showAssemblyList, setShowAssemblyList] = useState(false);
  const [positions, setPositions] = useState([]);
  const [checkedOrderItems, setCheckedOrderItems] = useState([]);
  const [assemblyListId, setAssemblyListId] = useState(null)

  const [marketPlaceChoices, setMarketPlaceChoices] = useState([])
  const [productBrandChoices, setProductBrandChoices] = useState([])
  const [productNameChoices, setProductNameChoices] = useState([])

  const [deliveryType, setDeliveryType] = useState(undefined)
  const [isKgt, setIsKgt] = useState(undefined)
  const [selectedMarketPlace, setSelectedMarketPlace] = useState(undefined)
  const [selectedProductBrand, setSelectedProductBrand] = useState(undefined)
  const [selectedProductName, setSelectedProductName] = useState(undefined)


  useEffect(() => {
    analyticAPI.get("get-order-items-choices/?field=product__name").then(
      res => setProductNameChoices(res.data.choices.map(name => ({value: name, label: name})))
    ).catch(err => console.log(err))

    analyticAPI.get("get-order-items-choices/?field=product__brand").then(
      res => setProductBrandChoices(res.data.choices.map(brand => ({value: brand, label: brand})))
    ).catch(err => console.log(err))

    analyticAPI.get(`marketplace-types/`).then(
      response => {
        setMarketPlaceChoices(response.data.map((marketplace_type, idx) => ({value: idx, label: marketplace_type})))
      }
    )
  }, [])


  const [totalListsCreatedData, setTotalListsCreatedData] = useState([
    {name: 'Пн', "Кол-во": 0},
    {name: 'Вт', "Кол-во": 0},
    {name: 'Ср', "Кол-во": 0},
    {name: 'Чт', "Кол-во": 0},
    {name: 'Пт', "Кол-во": 0},
    {name: 'Сб', "Кол-во": 0},
    {name: 'Вск', "Кол-во": 0},
  ])
  const [totalLabelsCreatedData, setTotalLabelsCreatedData] = useState([
    {name: 'Пн', value: 0},
    {name: 'Вт', value: 0},
    {name: 'Ср', value: 0},
    {name: 'Чт', value: 0},
    {name: 'Пт', value: 0},
    {name: 'Сб', value: 0},
    {name: 'Вск', value: 0},
  ])
  const [totalShippedData, setTotalShippedData] = useState([
    {name: 'Пн', "Кол-во": 0},
    {name: 'Вт', "Кол-во": 0},
    {name: 'Ср', "Кол-во": 0},
    {name: 'Чт', "Кол-во": 0},
    {name: 'Пт', "Кол-во": 0},
    {name: 'Сб', "Кол-во": 0},
    {name: 'Вск', "Кол-во": 0},
  ])
  const delivery_type_options = [
    {value: [1, 2, 3], label: 'Все'},
    {value: 1, label: 'FBS'},
    {value: 2, label: 'DBS'},
    {value: 3, label: 'FBS Express'},
    {value: "kgt", label: 'КГТ'},
  ];
  const [totalListsCreatedSums, setTotalListsCreatedSums] = useState(0)
  const [totalLabelsCreatedSums, setTotalLabelsCreatedSums] = useState(0)
  const [totalShippedSums, setTotalShippedSums] = useState(0)
  const [totalOrdersPriceInMoySklad, setTotalOrdersPriceInMoySklad] = useState(0)
  const [totalOrdersPriceInMarketplaces, setTotalOrdersPriceInMarketplaces] = useState(0)
  const {login} = useAuth()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderBy, setOrderBy] = useState("-order__created_dt,order__number")

  const [limit, setLimit] = useState(20)
  const [offset, setOffset] = useState(0)
  const [ordersCount, setOrdersCount] = useState(0)
  const [urlParams, setUrlParams] = useState({})
  const [scrollPosition, setScrollPosition] = useState(0);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const formatNumber = (number) => {
    return Intl.NumberFormat("de").format(number)
  }

  useEffect(() => {
    if (login) {
      setLoading(true)
      let start = undefined
      let end = undefined

      if (startDate !== null && endDate !== null) {
        start = moment(startDate).format('YYYY-MM-DD');
        end = moment(endDate).format('YYYY-MM-DD');
      }

      const statParams = {
        "product__is_kgt": isKgt,
        "start_date": start,
        "end_date": end,
        "delivery_type": deliveryType,
        "product_brand": selectedProductBrand,
        "product_name": selectedProductName,
        "marketplace": selectedMarketPlace,
      }

      analyticAPI.get(`get-statistics/`, {params: statParams}).then(
        response => {
          setTotalListsCreatedData(response.data.charts.lists_created_chart)
          setTotalLabelsCreatedData(response.data.charts.labels_created_chart)
          setTotalShippedData(response.data.charts.shipped_chart)
          setTotalListsCreatedSums(formatNumber(response.data.sums.total_lists_created))
          setTotalLabelsCreatedSums(formatNumber(response.data.sums.total_labels_created))
          setTotalShippedSums(formatNumber(response.data.sums.total_shipped))
          setTotalOrdersPriceInMoySklad(formatNumber(response.data.sums.total_orders_price_in_moy_sklad))
          setTotalOrdersPriceInMarketplaces(formatNumber(response.data.sums.total_orders_price_in_marketplaces))
        }
      ).catch(error => console.log(error))
      let order_items_url = `order-items/`
      let params = {
        ...urlParams,
        "order__shipped_dt__gte": start,
        "order__shipped_dt__lte": end,
        "order__delivery_type": deliveryType,
        "product__name__icontains": selectedProductName,
        "product__brand__icontains": selectedProductBrand,
        "order__account__platform__platform_type": selectedMarketPlace,
        "ordering": orderBy,
        "limit": limit,
        "offset": offset
      }

      setUrlParams((prevState) => {
        return {
          ...params
        }
      })

      analyticAPI.get(order_items_url, {params: params}).then(
        response => {
          setOffset(0)
          setOrdersCount(response.data.count)
          setOrdersItems((prevState) => {
            return response.data.results
          })
        }
      ).catch(error => console.log(error)).finally(() => setLoading(false))
    }

  }, [login, startDate, endDate, deliveryType, selectedMarketPlace, selectedProductName, selectedProductBrand, orderBy, isKgt])

  useEffect(() => {
    if (offset === 0) {
      return
    }
    let params = urlParams
    params["limit"] = limit
    params["offset"] = offset
    let url = "order-items/"
    analyticAPI.get(url, {params: params}).then(
      response => {
        setOrdersCount(response.data.count)
        setOrdersItems((prevState) => {
          return [
            ...prevState,
            ...response.data.results
          ]
        })
      }
    ).catch(error => console.log(error)).finally(() => setLoading(false))
  }, [offset]);

  useEffect(() => {
    if (!loading && offset < ordersCount) {
      let body = document.body;
      let html = document.documentElement;

      let documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (documentHeight - window.innerHeight === scrollPosition) {
        setLoading(true)
        setOffset((prevState) => {
          return (prevState + limit)
        })
      }
    }
  }, [scrollPosition]);

  const trackScrolling = () => {
    setScrollPosition(document.documentElement.scrollTop)
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling, {passive: true});

    return () => {
      window.removeEventListener('scroll', trackScrolling);
    };
  }, []);

  const onDeliveryTypeChange = (value) => {
    if (value.value === "kgt") {
      setUrlParams((prevState) => {
          return {
            ...prevState,
            "product__is_kgt": 1,
          }
        }
      )
      setIsKgt(1)
      setDeliveryType(undefined)
    } else {
      setUrlParams((prevState) => {
        const {"product__is_kgt": _, ...newState} = prevState;
        return newState;
      });
      setIsKgt(undefined)
      console.log(value.value)
      setDeliveryType(value.value)
    }
  }

  return (
    <>
      <div className="connection-page">
        <img
          alt=""
          src="/images/page-bkg.svg"
          className="main-background-image"
        />
        <div className="content-area" style={showAssemblyList ? {"width": "55%"} : {}}>
          <h1 className="page-title">История сборки</h1>
          <div className="additional-to-header">

          </div>
          <div className="content-table history-content-table">
            <div className="charts">
              <div className="filters-custom-container chart-container">
                <div className="filters-history-container-data">
                  <p className="chart-container-header">Фильтры</p>
                  <img alt="" src="/images/filter.svg"></img>
                </div>
                <div className={"filters-selects-container"}>
                  <Select
                    defaultValue={undefined}
                    onChange={(e) => {
                      onDeliveryTypeChange(e ? e : "")
                    }}
                    options={delivery_type_options}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Тип доставки"
                    isClearable
                  />
                  <Select
                    defaultValue={undefined}
                    onChange={(e) => {
                      setSelectedProductName(e ? e.value : "")
                    }}
                    options={productNameChoices}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Продукт"
                    isClearable
                  />
                  <Select
                    defaultValue={undefined}
                    onChange={(e) => {
                      setSelectedMarketPlace(e ? e.value : "")
                    }}
                    options={marketPlaceChoices}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Маркетплейс"
                    isClearable
                  />
                  <Select
                    defaultValue={undefined}
                    onChange={(e) => {
                      setSelectedProductBrand(e ? e.value : "")
                    }}
                    options={productBrandChoices}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Бренд"
                    isClearable
                  />
                  <DatePicker
                    customInput={<ShipmentTimeSelector/>}
                    selectsRange={true}
                    locale="ru"
                    selected={startDate}
                    onChange={onDateChange}
                    endDate={endDate}
                    startDate={startDate}
                    isClearable
                  />
                </div>
              </div>
              <div className="chart-container">
                <div className="history-container-data">
                  <p className="chart-container-header">Всего отгружено<br/>товаров</p>
                  <p className="chart-container-total-data">{totalShippedSums}</p>
                </div>
                <BarChart
                  width={592}
                  height={231}
                  data={totalShippedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name"/>
                  <YAxis/>
                  <Tooltip/>
                  <Bar dataKey="Кол-во" fill="#FF0909" activeBar={<Rectangle fill="#FF0909" stroke="#FF0909"/>}/>
                </BarChart>
              </div>
              <div className="separated-chart-container">
                <div className="chart-container">
                  <div className="history-container-data">
                    <p className="chart-container-header-secondary">Сформировано<br/>этикеток</p>
                    <p className="chart-container-total-data-secondary">{totalLabelsCreatedSums}</p>
                  </div>
                </div>
                <div className="chart-container">
                  <div className="history-container-data">
                    <p className="chart-container-header-secondary">Сформировано<br/>листов</p>
                    <p className="chart-container-total-data-secondary">{totalListsCreatedSums}</p>
                  </div>
                </div>
              </div>
              <div className="separated-chart-container">
                <div className="chart-container">
                  <div className="history-container-data">
                    <p className="chart-container-header-secondary">Общая стоимость<br/>отгрузок по РРЦ в МС</p>
                    <p className="chart-container-total-data-secondary">{totalOrdersPriceInMoySklad} ₽</p>
                  </div>
                </div>
                <div className="chart-container">
                  <div className="history-container-data">
                    <p className="chart-container-header-secondary">Общая стоимость по цене<br/>заказов на МП</p>
                    <p className="chart-container-total-data-secondary">{totalOrdersPriceInMarketplaces} ₽</p>
                  </div>
                </div>
              </div>
            </div>
            <OrdersTable ordersItems={ordersItems} customTableClassname={"history-table"}
                         loading={loading} positions={positions} setAssemblyListId={setAssemblyListId}
                         setShowAssemblyList={setShowAssemblyList} setOrderBy={setOrderBy}/>
          </div>
        </div>
        {!showAssemblyList ? (
          <></>
        ) : <div className="control-tabs control-tabs-assembly"><AssemblyReadOnlyList ordersItems={checkedOrderItems}
                                                                                      setShowAssemblyList={setShowAssemblyList}
                                                                                      assemblyListId={assemblyListId}
                                                                                      setPositions={setPositions}
                                                                                      setCheckedOrderItems={setCheckedOrderItems}/>
        </div>
        }
      </div>
    </>
  )
}
