import React, {useEffect, useState} from "react";
import {ExportReport} from "./ExportReport";
import {ImportReport} from "./ImportReport";
import {analyticAPI} from "../../api";
import {NotificationManager} from "react-notifications";

export const ImportExportTab = ({setShowImportExportTab}) => {
  const [selectedTab, setSelectedTab] = useState("import");
  const fieldsToExport = ["Группы - сгруппированные товары", "UUID", "Тип", "Код-баркод", "Наименование", "Внешний код", "Артикул", "Валюта", "Цена РРЦ МС", "Цена на маркетплейсе", "Закупочная цена", "Остатки",]
  const [checkedFieldsToExport, setCheckedFieldsToExport] = useState(fieldsToExport)
  const [file, setFile] = useState(null);
  const onSaveAction = () => {
    if (selectedTab === "import") {
      const formData = new FormData();
      formData.append('file', file);
      analyticAPI.post(
        "excel-import/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(
        NotificationManager.info("Файл успешно загружен!")
      ).catch(err => console.log(err))
    } else {
      NotificationManager.info("Файл будет доступен через несколько секунд!")
      analyticAPI.post("warehouse-export/", {table_fields: checkedFieldsToExport}, {responseType: 'blob'}).then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[:T]/g, '-');

          const fileName = `ТоварыМС-${formattedDate}.xlsx`;
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', fileName); // Set the file name
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      ).catch(err => console.log(err))
    }
  }

  return (
    <div className="import-export-tab">
      <div className="shops-header">
        <p className="shops-header-text">Выгрузка и настройка отчета</p>
        <img className="shops-header-cross" src="/images/cross.svg" alt=""
             onClick={() => setShowImportExportTab(false)}/>
      </div>
      <div className="import-filters-container">
        <div className="import-export-tabs-container">
          <div className={selectedTab === "import" ? "filter-label" : ""} onClick={() => setSelectedTab("import")}>
            <p className={selectedTab === "import" ? "selected-filter" : "filter"}>Импорт</p>
          </div>
          <div className={selectedTab === "export" ? "filter-label" : ""} onClick={() => setSelectedTab("export")}>
            <p className={selectedTab === "export" ? "selected-filter" : "filter"}>Экспорт</p>
          </div>
        </div>
      </div>
      {selectedTab === "export" ? <ExportReport checkedFieldsToExport={checkedFieldsToExport}
                                                setCheckedFieldsToExport={setCheckedFieldsToExport}
                                                fieldsToExport={fieldsToExport}
      /> : <></>}
      {selectedTab === "import" ? <ImportReport setFile={setFile} file={file}/> : <></>}
      <div className="export-action-container">
        <button className="btn btn-negative no-background search-input-container"
                onClick={() => setShowImportExportTab(false)}>Отменить
        </button>
        <button className="btn btn-primary" onClick={onSaveAction}>Сохранить</button>
      </div>
    </div>
  )
}