import {LoginPage} from "../pages/LoginPage";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {ConnectionsPage} from "../pages/ConnectionsPage";
import {NavigationPanel} from "./NavigationPanel";
import {useAuth} from "./AuthProvider";
import {OrdersPage} from "../pages/OrdersPage";
import {HistoryPage} from "../pages/HistoryPage";
import {ImportExportPage} from "../pages/ImportExportPage";

export const APRoutes = () => {
  const {token} = useAuth()
  return (
    <Router>
      <Switch>
        {
          !token ? (
            <Route path="*">
              <Redirect to="/login"/>
              <Route exact path="/login" component={LoginPage} />
            </Route>
          ) : (
            <>
              <NavigationPanel />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/" component={HistoryPage}></Route>
              <Route exact path="/orders" component={OrdersPage}></Route>
              <Route exact path="/connections" component={ConnectionsPage}></Route>
              <Route exact path="/import-export" component={ImportExportPage}></Route>
            </>
          )
        }

      </Switch>
    </Router>

  )
}
