import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import "../styles/connections.css"
import {useEffect, useState} from "react";
import {analyticAPI} from "../api";
import React from "react";

export const NavigationPanel = () => {
  let pathName = useLocation().pathname
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    analyticAPI.get("notifications/").then(
      res => setNotifications(res.data.results)
    ).catch(err => console.log(err))

    const intervalId = setInterval(() => {
      analyticAPI.get("notifications/").then(
        res => setNotifications(res.data.results)
      ).catch(err => console.log(err))
    }, 60000 * 3);

    return () => clearInterval(intervalId);
  }, [])


  const deleteNotification = (notificationId) => {
    analyticAPI.delete(`notifications/${notificationId}/`).then(
      res => {
        analyticAPI.get("notifications/").then(
          res => setNotifications(res.data.results)
        ).catch(err => console.log(err))
      }
    ).catch(err => console.log(err))
  }

  const markAsRead = (notificationId) => {
    analyticAPI.patch(`notifications/${notificationId}/`, {is_read: true}).then(
      res => {
        analyticAPI.get("notifications/").then(
          res => setNotifications(res.data.results)
        ).catch(err => console.log(err))
      }
    ).catch(err => console.log(err))
  }

  return (
    <div className="navigation-container">
      <div className="nav">
        <div className="frame-wrapper">
          <img alt="" className="image-74" src="/images/nav_logo.svg"/>
          <Link to="/">
            <img alt="" className="container"
                 src={pathName === "/" ? "/images/history_active.svg" : "/images/history.svg"}/>
          </Link>
          <Link to="/orders">
            <img alt="" className="container"
                 src={pathName === "/orders" ? "/images/stock_active.svg" : "/images/stock.svg"}/>
          </Link>
          <Link to="/import-export">
            <img alt="" className="container"
                 src={pathName === "/import-export" ? "/images/import_export_active.svg" : "/images/import_export.svg"}/>
          </Link>
          <Link to="/connections">
            <img alt="" className="container"
                 src={pathName === "/connections" ? "/images/connections_active.svg" : "/images/connections.svg"}/>
          </Link>
        </div>
        <div className="frame-wrapper">
          <div className="frame-34298">
            <div className="frame-5746">
              <div className={"notifications-all-container"}>
                <img onClick={() => setShowNotifications(!showNotifications)} alt="" className="navigation"
                     src={notifications.length !== 0 ? "/images/active_notifications.png" : "/images/notifications.png"}/>
                {showNotifications && notifications.length !== 0 ? (
                    <div className="notifications">
                      <div className={"cancel-row"} onClick={() => setShowNotifications(false)}><img alt=""
                                                                                                     src="/images/cross_red.svg"></img>
                      </div>
                      {notifications.map(notification => (
                          <div onClick={() => deleteNotification(notification.id)}
                               className={"notification-item" + (notification.is_read ? "" : " not-read")}
                               onMouseOver={() => markAsRead(notification.id)}>
                            <p>Срочный заказ № {notification.order_number}</p>
                            <p>Вам пришел срочный заказ!</p>
                          </div>
                        )
                      )}
                    </div>) :
                  <></>
                }
              </div>
              <img alt="" className="navigation" src="/images/settings.svg"/>
              <img alt="" className="navigation" src="/images/navigation.svg"/>
              <img alt="" className="component-36" src="/images/profile.svg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
