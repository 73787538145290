import Checkbox from "react-custom-checkbox";
import React, { useEffect, useState } from "react";
import "../styles/connections.css";
import { analyticAPI } from "../api";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ShortTimeSelector } from "./ShortTimeSelector";
import moment from "moment-timezone";

export const AssemblyList = ({
  ordersItems,
  setShowAssemblyList,
  assemblyListId,
  handleOnCheck,
  setPositions,
  setCheckedOrderItems,
  positions,
  setAssemblyListId,
  loadOrders,
}) => {
  const [assemblyListOrderItems, setAssemblyListOrderItems] =
    useState(ordersItems);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [brandChoices, setBrandChoices] = useState([]);
  const [marketPlaceChoices, setMarketPlaceChoices] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedMarketPlace, setSelectedMarketplace] = useState("");

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setAssemblyListOrderItems(ordersItems);
  }, [ordersItems]);

  const createAssemblyList = () => {
    analyticAPI
      .post("assembly-list/", {
        order_items: ordersItems.map((item) => item.id),
        name: "123",
      })
      .then((res) => setAssemblyListId(res.data.id))
      .catch((err) => console.log(err));
    loadOrders();
    setCheckedOrderItems([]);
    setPositions([]);
  };

  const exportExcel = () => {
    if (assemblyListId == null) {
      NotificationManager.error("Сохраните лист перед печатью");
      return;
    }

    analyticAPI
      .post(
        `assembly-list/${assemblyListId}/export/`,
        { id: assemblyListId },
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const currentDate = new Date();
        const formattedDate = currentDate
          .toISOString()
          .slice(0, 19)
          .replace(/[:T]/g, "-");

        const fileName = `Отчет-${formattedDate}.xlsx`;
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", fileName); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const createArchive = () => {
    NotificationManager.info("Этикетки печатаются!");
    analyticAPI
      .post(
        "assembly-list/archive/",
        { order_items: assemblyListOrderItems },
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" }),
        );
        const currentDate = new Date();
        const formattedDate = currentDate
          .toISOString()
          .slice(0, 19)
          .replace(/[:T]/g, "-");

        const fileName = `Этикетки-${formattedDate}.zip`;
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", fileName); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) =>
        NotificationManager.error("У данных товаров нет этикеток."),
      );
  };

  const deleteAssemblyList = () => {
    if (assemblyListId) {
      analyticAPI
        .delete(`assembly-list/${assemblyListId}/`)
        .then((r) => setAssemblyListOrderItems([]))
        .catch((err) => console.log(err));
    }
    setShowAssemblyList(false);
  };

  const updateOrdersStatus = () => {
    if (assemblyListId) {
      analyticAPI.patch(
        "order/status", {orders: ordersItems.map(orderItem => orderItem.order.number), status: "Доставляется"}
      )
    }
  }

  useEffect(() => {
    if (assemblyListId) {
      analyticAPI
        .get(`assembly-list/${assemblyListId}/`)
        .then((result) => {
          setAssemblyListOrderItems(result.data.order_items);
          setCheckedOrderItems(result.data.order_items);
          setPositions(result.data.order_items.map((item) => item.id));
        })
        .catch((err) => console.log(err));
    }
  }, [assemblyListId]);

  useEffect(() => {
    let filtered = ordersItems;
    if (selectedBrand !== "") {
      filtered = ordersItems.filter(
        (orderItem) => orderItem.product.brand === selectedBrand.value,
      );
    }
    if (selectedMarketPlace !== "") {
      filtered = filtered.filter(
        (orderItem) =>
          orderItem.order.platform_name === selectedMarketPlace.label,
      );
    }

    if (startDate !== null && endDate !== null) {
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");
      filtered = filtered.filter(
        (orderItem) =>
          orderItem.order.shipped_dt >= start &&
          orderItem.order.shipped_dt <= end,
      );
    }

    setAssemblyListOrderItems(filtered);
  }, [selectedBrand, selectedMarketPlace, startDate, endDate]);

  useEffect(() => {
    analyticAPI
      .get(
        `get-order-items-choices/?field=product__brand&ids=${ordersItems.map((orderItem) => orderItem.id)}`,
      )
      .then((res) =>
        setBrandChoices(
          res.data.choices.map((brand) => ({ value: brand, label: brand })),
        ),
      )
      .catch((err) => console.log(err));

    analyticAPI.get(`marketplace-types/?with_moy_sklad=1`).then((response) => {
      setMarketPlaceChoices(
        response.data.map((marketplace_type, idx) => ({
          value: idx,
          label: marketplace_type,
        })),
      );
    });
  }, [ordersItems]);

  return (
    <div className="shops assembly-list-orders-container">
      <div className="shops-content">
        <div className="shops-header">
          <p className="shops-header-text">Лист сборки</p>
          <img
            className="shops-header-cross"
            src="/images/cross.svg"
            alt=""
            onClick={() => setShowAssemblyList(false)}
          />
        </div>
        <div className="shops-filters">
          <Select
            defaultValue={undefined}
            onChange={(e) => {
              setSelectedBrand(e);
            }}
            options={brandChoices}
            // isDisabled={true}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Бренд"
          />
          <Select
            defaultValue={undefined}
            onChange={(e) => {
              setSelectedMarketplace(e);
            }}
            options={marketPlaceChoices}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Маркетплейсы"
          />
          <DatePicker
            customInput={<ShortTimeSelector />}
            selectsRange={true}
            selected={startDate}
            onChange={onDateChange}
            endDate={endDate}
            startDate={startDate}
            locale="ru"
          />
          <button className="btn btn-negative no-background search-input-container quantity-sort-button">
            <p>Шт</p>
            <img
              alt=""
              className="short-time-input-image"
              src="/images/up_arrow.svg"
              width="16px"
              height="16px"
            />
          </button>
        </div>
      </div>
      <div className="assembly-table-container">
        <table className="orders-table assembly-table">
          <tbody>
            {assemblyListOrderItems.length ? (
              assemblyListOrderItems.map((orderItem) => (
                <tr>
                  <td className="checkbox-col">
                    <div className="table-header-item-container">
                      <Checkbox
                        checked={positions.includes(orderItem.id)}
                        onChange={() => handleOnCheck(orderItem)}
                        className="custom-checkbox"
                        icon={
                          <img
                            src="/images/checbox.svg"
                            style={{ width: "28px" }}
                            alt=""
                          />
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <p>{orderItem.order.platform_name !== "Wildberries"
                      ? orderItem.order.number
                      : orderItem.external_id}</p>
                  </td>
                  <td>
                    <p>{orderItem.product.name}</p>
                  </td>
                  <td>
                    <p>{orderItem.order.shipped_dt}</p>
                  </td>
                  <td>
                    <p>{orderItem.quantity}</p>
                  </td>
                </tr>
              ))
            ) : (
              <div className="empty-order-items">
                <img
                  className="empty-order-image"
                  src="/images/orderItems.svg"
                  alt=""
                ></img>
                <p className="empty-order-header">У вас не выбраны товары</p>
                <p className="empty-order-text">
                  Чтобы добавить товары в лист сборки выберите товар через
                  нажатие на галочку.
                </p>
              </div>
            )}
          </tbody>
        </table>
      </div>
      {assemblyListOrderItems.length ? (
        <div className="order-items-action-panel">
          <div className={"order-items-action-panel-item"} style={{gap: "2px"}}>
            <img
              src="/images/orderItemsExport.svg"
              alt=""
              onClick={exportExcel}
              style={{ cursor: "pointer" }}
            />
            <img
              src="/images/printStickers.png"
              style={{ cursor: "pointer" }}
              alt=""
              onClick={createArchive}
            />
          </div>
          <div className={"order-items-action-panel-item"} style={{gap: "16px"}}>
            <button className="btn btn-primary" onClick={createAssemblyList}>
              Сохранить
            </button>
            <button className="btn btn-primary" onClick={updateOrdersStatus}>
              Принять
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
