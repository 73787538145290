import React, {forwardRef} from "react";


export const ShortTimeSelector = forwardRef(({value, onClick}, ref) => (
    <div className="short-time-input btn btn-negative search-input-container" onClick={onClick}>
      <input type={"hidden"} value={value}></input>
      <img alt=""
           className="short-time-input-image"
           src="/images/calendar.svg"
      />
    </div>
  )
)
