import React from "react";
import "../../styles/connections.css"
import {OrdersCartTab} from "../orders/OrdersCartTab";
import {OrdersAccountsTab} from "../orders/OrdersAccountsTab";
import {ImportExportTab} from "./ImportExportTab";

export const ImportExportTabs = ({
                                   setLoading,
                                   platformsToFilter,
                                   setPlatformsToFilter,
                                   accountsToFilter,
                                   setAccountsToFilter,
                                   showCartTab,
                                   showAccountsTab,
                                   setShowCartTab,
                                   setShowAccountsTab,
                                   showImportExportTab,
                                   setShowImportExportTab
                                 }) => {
  return (
    <div className="control-tabs">
      {showCartTab ? <OrdersCartTab setLoading={setLoading} platforms={platformsToFilter}
                                    setPlatformsToFilter={setPlatformsToFilter}
                                    setShowCartTab={setShowCartTab} showCartTab={showCartTab}/> : <></>}
      {showAccountsTab ?
        <OrdersAccountsTab setLoading={setLoading} accountsToFilter={accountsToFilter}
                           setAccountsToFilter={setAccountsToFilter} showAccountsTab={showAccountsTab}
                           platformsToFilter={platformsToFilter}
                           setShowAccountsTab={setShowAccountsTab}/> : <></>}
      {
        showImportExportTab ? <ImportExportTab setShowImportExportTab={setShowImportExportTab}/> : <></>
      }

    </div>
  )
}
