import React, { useEffect, useState } from "react";
import "../styles/orders.scss";
import { useAuth } from "../components/AuthProvider";
import { OrdersHeader } from "../components/orders/OrdersHeader";
import Select from "react-select";
import { OrdersTable } from "../components/orders/OrdersTable";
import { OrdersTabs } from "../components/orders/OrdersTabs";
import { OrdersSearch } from "../components/orders/OrdersSearch";
import { analyticAPI } from "../api";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale/ru";
import { ShipmentTimeSelector } from "../components/ShipmentTimeSelector";
import { AssemblyList } from "../components/AssemblyList";
import moment from "moment-timezone";
import makeAnimated from "react-select/animated";

registerLocale("ru", ru);

export const OrdersPage = () => {
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    all: 0,
    urgent: 0,
    today: 0,
    other: 0,
  });
  const [ordersItems, setOrdersItems] = useState([]);
  const [accountsToFilter, setAccountsToFilter] = useState([]);
  const [platformsToFilter, setPlatformsToFilter] = useState([]);
  const [showAccountsTab, setShowAccountsTab] = useState(false);
  const [showCreateStockTab, setShowCreateStockTab] = useState(false);
  const [showCartTab, setShowCartTab] = useState(false);
  const [showAssemblyList, setShowAssemblyList] = useState(false);
  const [showCheckListTab, setShowChecklistTab] = useState(false);

  const [sortBy] = useState("market");
  const [ordersType, setOrdersType] = useState("");
  const [linked, setLinked] = useState(false);
  const [link, setLink] = useState(false);

  const [checkedOrderItems, setCheckedOrderItems] = useState([]);
  const [positions, setPositions] = useState([]);
  const [assemblyListId, setAssemblyListId] = useState(null);
  const [ordersCount, setOrdersCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const [urlParams, setUrlParams] = useState({});

  const [orderBy, setOrderBy] = useState("-order__created_dt,order__number");

  const animatedComponent = makeAnimated();

  const handleOnCheck = (orderItem) => {
    if (positions.includes(orderItem.id)) {
      const updatedPositions = positions.filter((pos) => pos !== orderItem.id);
      const updatedOrders = checkedOrderItems.filter(
        (item) => item.id !== orderItem.id,
      );

      setPositions(updatedPositions);
      setCheckedOrderItems(updatedOrders);
    } else {
      setCheckedOrderItems([...checkedOrderItems, orderItem]);
      setPositions([...positions, orderItem.id]);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [deliveryType, setDeliveryType] = useState(null);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { login } = useAuth();

  const delivery_type_options = [
    { value: null, label: "Все" },
    { value: 1, label: "FBS" },
    { value: 2, label: "DBS" },
    { value: 3, label: "FBS Express" },
    { value: "kgt", label: "КГТ" },
  ];

  const onStatusChange = (value) => {
    setSelectedStatuses(value.map((item) => item.value));
    setUrlParams((prevState) => {
      return {
        ...prevState,
        order__status__name__in: value.map((item) => item.value),
      };
    });
  };

  const onDeliveryTypeChange = (value) => {
    if (value.value === "kgt") {
      setUrlParams((prevState) => {
        return {
          ...prevState,
          product__is_kgt: 1,
        };
      });
      setDeliveryType(undefined);
    } else {
      setUrlParams((prevState) => {
        const { product__is_kgt: _, ...newState } = prevState;
        return newState;
      });
      setDeliveryType(value.value);
    }
  };

  const loadOrders = () => {
    if (login) {
      setLoading(true);
      analyticAPI
        .get(`get-orders-counts/`)
        .then((response) => {
          setCounts(response.data);
        })
        .catch((error) => console.log(error));

      let url = "order-items/";
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");

      let params = {
        ...urlParams,
        ordering: orderBy,
        orders_type: link ? link : undefined,
        order__account__platform__platform_type__in: platformsToFilter,
        order__account__in: accountsToFilter,
        sort_by: sortBy,
        limit: limit,
        offset: offset,
        search: searchQuery ? searchQuery : undefined,
      };

      if (startDate !== null && endDate !== null) {
        params["order__real_shipped_dt__gte"] = start;
        params["order__real_shipped_dt__lte"] = end;
        setUrlParams((prevState) => {
          return {
            ...prevState,
            order__real_shipped_dt__gte: start,
            order__real_shipped_dt__lte: end,
          };
        });
      }

      if (deliveryType !== null) {
        params["order__delivery_type"] = deliveryType;
        setUrlParams((prevState) => {
          return {
            ...prevState,
            order__delivery_type: deliveryType,
          };
        });
      }

      setUrlParams((prevState) => {
        return {
          ...prevState,
          ordering: orderBy,
          orders_type: linked ? link : undefined,
          order__account__platform__platform_type__in: platformsToFilter,
          order__account__in: accountsToFilter,
          sort_by: sortBy,
          search: searchQuery ? searchQuery : undefined,
        };
      });

      analyticAPI
        .get(url, { params: params })
        .then((response) => {
          setOffset(0);
          setOrdersCount(response.data.count);
          setOrdersItems((prevState) => {
            return response.data.results;
          });
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  };

  const getStatuses = () => {
    let url = "get-order-items-choices/?field=order__status__name";
    analyticAPI
      .get(url)
      .then((response) => {
        setStatuses(
          response.data.choices.map((status) => ({
            label: status,
            value: status,
          })),
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    loadOrders();
  }, [
    login,
    startDate,
    endDate,
    deliveryType,
    orderBy,
    link,
    linked,
    platformsToFilter,
    accountsToFilter,
    sortBy,
    searchQuery,
    selectedStatuses,
  ]);

  useEffect(() => {
    if (offset === 0) {
      return;
    }
    let params = urlParams;
    params["limit"] = limit;
    params["offset"] = offset;
    let url = "order-items/";
    analyticAPI
      .get(url, { params: params })
      .then((response) => {
        setOrdersCount(response.data.count);
        setOrdersItems((prevState) => {
          return [...prevState, ...response.data.results];
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [offset]);

  useEffect(() => {
    if (!loading && offset < ordersCount) {
      let body = document.body;
      let html = document.documentElement;

      let documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      if (documentHeight - window.innerHeight === scrollPosition) {
        setLoading(true);
        setOffset((prevState) => {
          return prevState + limit;
        });
      }
    }
  }, [scrollPosition]);

  const trackScrolling = () => {
    setScrollPosition(document.documentElement.scrollTop);
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling, { passive: true });

    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  const openCheckListTab = () => {
    if (!showAssemblyList) {
      setShowChecklistTab(!showCheckListTab);
    }
  };

  const openAssemblyList = () => {
    if (!showCheckListTab) {
      setShowCartTab(!showAssemblyList ? false : showCartTab);
      setShowAccountsTab(!showAssemblyList ? false : showAccountsTab);
      setShowAssemblyList(!showAssemblyList);
    }
  };

  return (
    <>
      <div className="connection-page">
        <img
          alt=""
          src="/images/page-bkg.svg"
          className="main-background-image"
        />
        <div
          className={"content-area"}
          style={
            showCheckListTab
              ? { width: "62%" }
              : showCreateStockTab
                ? { width: "69%" }
                : showAssemblyList
                  ? { width: "55%" }
                  : showCartTab || showAccountsTab
                    ? { width: "70%" }
                    : {}
          }
        >
          <h1 className="page-title">Склад</h1>
          <div className="header">
            <OrdersHeader
              ordersType={ordersType}
              setOrdersType={setOrdersType}
              counts={counts}
              setLinked={setLinked}
              setLink={setLink}
            />
            <div className="controlls">
              <OrdersSearch
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
              <div
                className={
                  "btn btn-negative search-input-container" +
                  (showAccountsTab ? " active" : "")
                }
                onClick={() => {
                  setShowAssemblyList(
                    !showAccountsTab ? false : showAssemblyList,
                  );
                  setShowAccountsTab(!showAccountsTab);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7.4998H16.21L11.82 0.929805C11.42 0.339805 10.55 0.339805 10.16 0.929805L5.77 7.4998H1C0.45 7.4998 0 7.9498 0 8.4998C0 8.5898 0.00999996 8.6798 0.04 8.7698L2.58 18.0398C2.81 18.8798 3.58 19.4998 4.5 19.4998H17.5C18.42 19.4998 19.19 18.8798 19.43 18.0398L21.97 8.7698L22 8.4998C22 7.9498 21.55 7.4998 21 7.4998ZM10.99 3.2898L13.8 7.4998H8.18L10.99 3.2898ZM11 15.4998C9.9 15.4998 9 14.5998 9 13.4998C9 12.3998 9.9 11.4998 11 11.4998C12.1 11.4998 13 12.3998 13 13.4998C13 14.5998 12.1 15.4998 11 15.4998Z"
                    fill="#45475D"
                  />
                </svg>
              </div>
              <div
                className={
                  "btn btn-negative search-input-container" +
                  (showCartTab ? " active" : "")
                }
                onClick={() => {
                  setShowAssemblyList(!showCartTab ? false : showAssemblyList);
                  setShowCartTab(!showCartTab);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 16.5C4.9 16.5 4.01 17.4 4.01 18.5C4.01 19.6 4.9 20.5 6 20.5C7.1 20.5 8 19.6 8 18.5C8 17.4 7.1 16.5 6 16.5ZM0 1.5C0 2.05 0.45 2.5 1 2.5H2L5.6 10.09L4.25 12.53C3.52 13.87 4.48 15.5 6 15.5H17C17.55 15.5 18 15.05 18 14.5C18 13.95 17.55 13.5 17 13.5H6L7.1 11.5H14.55C15.3 11.5 15.96 11.09 16.3 10.47L19.88 3.98C20.25 3.32 19.77 2.5 19.01 2.5H4.21L3.54 1.07C3.38 0.72 3.02 0.5 2.64 0.5H1C0.45 0.5 0 0.95 0 1.5ZM16 16.5C14.9 16.5 14.01 17.4 14.01 18.5C14.01 19.6 14.9 20.5 16 20.5C17.1 20.5 18 19.6 18 18.5C18 17.4 17.1 16.5 16 16.5Z"
                    fill="#45475D"
                  />
                </svg>
              </div>
              <div
                className={
                  "btn btn-negative search-input-container" +
                  (showCheckListTab ? " active" : "")
                }
                onClick={openCheckListTab}
              >
                <img
                  id="doc"
                  className=""
                  width="24px"
                  height="24px"
                  src={
                    showCheckListTab
                      ? "/images/checklist-active.svg"
                      : "/images/checklist.svg"
                  }
                  alt=""
                />
              </div>
              <div
                className="btn btn-negative search-input-container"
                onClick={() => setShowCreateStockTab(!showCreateStockTab)}
              >
                <img
                  id="doc"
                  className=""
                  width="24"
                  height="24"
                  src={
                    showCreateStockTab
                      ? "/images/create-stock-active.svg"
                      : "/images/create-stock.svg"
                  }
                  alt=""
                />
              </div>
              <button
                onClick={openAssemblyList}
                style={{ minHeight: "39px" }}
                className="btn btn-primary"
              >
                <p>Создать лист сборки</p>
              </button>
            </div>
          </div>
          <div className="additional-to-header">
            <div className="orders-filters">
              <Select
                defaultValue={undefined}
                onChange={onDeliveryTypeChange}
                options={delivery_type_options}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Тип доставки"
              />
              <DatePicker
                customInput={<ShipmentTimeSelector />}
                selectsRange={true}
                selected={startDate}
                onChange={onDateChange}
                endDate={endDate}
                startDate={startDate}
                locale="ru"
              />
              <Select
                onChange={onStatusChange}
                options={statuses}
                closeMenuOnSelect={false}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Статус заказа"
                components={animatedComponent}
                isMulti
              />
            </div>
            <div className="colors-helpers">
              <div className="color-helper urgent-color-helper">
                <div className="color-circle kgt-color-circle"></div>
                <p className="">Крупногабаритное</p>
              </div>
              <div className="color-helper urgent-color-helper">
                <div className="color-circle set-color-circle"></div>
                <p className="">Комплект</p>
              </div>
            </div>
          </div>
          <div className="content-table">
            <OrdersTable
              ordersItems={ordersItems}
              loading={loading}
              handleOnCheck={handleOnCheck}
              checkedOrderItems={checkedOrderItems}
              positions={positions}
              setAssemblyListId={setAssemblyListId}
              setShowAssemblyList={setShowAssemblyList}
              showAssemblyList={showAssemblyList}
              setOrderBy={setOrderBy}
              setCheckedOrderItems={setCheckedOrderItems}
              setPositions={setPositions}
            />
          </div>
        </div>
        {!showCartTab &&
        !showAccountsTab &&
        !showCreateStockTab &&
        !showCheckListTab ? (
          <></>
        ) : (
          <OrdersTabs
            setLoading={setLoading}
            platformsToFilter={platformsToFilter}
            setPlatformsToFilter={setPlatformsToFilter}
            accountsToFilter={accountsToFilter}
            setAccountsToFilter={setAccountsToFilter}
            showCartTab={showCartTab}
            showAccountsTab={showAccountsTab}
            setShowCartTab={setShowCartTab}
            setShowAccountsTab={setShowAccountsTab}
            showCreateStockTab={showCreateStockTab}
            ordersItems={checkedOrderItems}
            positions={positions}
            handleOnCheck={handleOnCheck}
            setPositions={setPositions}
            setCheckedOrderItems={setCheckedOrderItems}
            setShowCreateStockTab={setShowCreateStockTab}
            showCheckListTab={showCheckListTab}
            setShowChecklistTab={setShowChecklistTab}
          />
        )}
        {!showAssemblyList ? (
          <></>
        ) : (
          <div className="control-tabs control-tabs-assembly">
            <AssemblyList
              ordersItems={checkedOrderItems}
              setShowAssemblyList={setShowAssemblyList}
              positions={positions}
              assemblyListId={assemblyListId}
              handleOnCheck={handleOnCheck}
              setPositions={setPositions}
              setCheckedOrderItems={setCheckedOrderItems}
              setAssemblyListId={setAssemblyListId}
              loadOrders={loadOrders}
            />
          </div>
        )}
      </div>
    </>
  );
};
