import Select from "react-select";
import React, {forwardRef} from "react";


export const ShipmentTimeSelector = forwardRef(({value, onClick}, ref) => (
    <Select
      ref={ref}
      onInputChange={onClick}
      onMenuOpen={onClick}
      defaultValue={undefined}
      options={[]}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={!value ? "Период отгрузки" : value}
      isClearable
    >
      {value}
    </Select>
  )
)
